.signup-page-wrapper {
  width: 100%;
  min-height: 100vh;
  // position: fixed;
  position: relative;
  overflow-x: hidden;
  background-color: #e5e5e5;

  .page-info-wrapper {
    top: 300px;
    position: relative;
    left: 8%;
    margin-right: 8%;
  }

  @media (min-width: 320px) {
    .page-info-wrapper {
      top: 160px !important;
    }
  }

  .round-wrapper {
    background: #ffffff;
    border-radius: 40px;
    max-width: 922px;
    margin: 0 auto;
    margin-top: 195px;
    // display: block;
    padding: 0px 57px;
    margin-bottom: 38px;

    .row {
      > div {
        height: 90px;
      }
    }
  }

  .round-wrapper-login {
    position: relative;
    // left: 20%;
    background: #ffffff;
    border-radius: 40px;
    max-width: 598px;
    margin: 0 auto;
    margin-top: 60px;
    // display: block;
    padding: 37px;

    .row {
      > div {
        height: 90px;
      }
    }
  }

  .title-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #c7c7c7;

    &.dark {
      color: #848484;
    }
  }

  .input-wrapper {
    label {
      display: block;
      text-align: left;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: #808191;
      margin: 0 auto;
      margin-left: 17px;
    }

    input {
      text-align: left;
      display: block;
      background: rgba(228, 228, 228, 0.49776);
      border: 2px solid #e3f3f8;
      border-radius: 8px;
      padding: 15px 23px;
      width: 90.7%;
      margin: 0 auto;
      margin-top: 10px;
      max-width: 417px;
      margin-bottom: 10px;
    }
  }

  .form-group {
    text-align: center;
    // margin-top: -83px;
    /* button{
            
        } */
  }

  .signup-instructions {
    // margin-bottom: 47px;
    display: inline-block;
  }

  .logo {
    img,
    svg {
      margin-top: 68px;
    }
  }

  select {
    text-align: left;
    display: block;
    background: rgba(228, 228, 228, 0.49776);
    border: 2px solid #e3f3f8;
    border-radius: 8px;
    padding: 18px 23px;
    width: 90.7%;
    margin: 0 auto;
    margin-top: 18px;
    max-width: 417px;
    margin-bottom: 18px;
  }

  .top-actions {
    .button-right {
      position: absolute !important;
      right: 7% !important;
      // top: 62px;
      top: 85px !important;
    }
  }
}

/* MY CSS HERE */

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 auto !important;
    //width: 44.333333% !important;
  }
}

.left-top-box {
  position: absolute !important;
  // top: 75px;
  left: 70px;
  z-index: 50 !important;
}

@media (max-width: 420px) {
  .left-top-box {
    left: 30px !important;
  }

  .signuplogo {
    margin-left: 0 !important;
  }

  .content-wrapper {
    overflow-x: hidden;
  }
}

input {
  &::placeholder {
    color: #ceb9b5;
  }
}
