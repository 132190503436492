.welcome-wrapper-new{
    .content-wrapper{
        margin-top: 110px;

        .badges-wrapper{
            >div:nth-child(2){
                margin-left: 50px;
            }
            >div:nth-child(3){
                margin-left: 50px;
            }
        }
    }
    .page-info-wrapper{
        // margin-top: 120px;
        margin-left: 10%;
        margin-right: 8%;
    }

    @keyframes backgroundColorPalette {
        0% {
            background: #6955ee;
        }
        20% {
            background: #0ece65;
        }
        40% {
            background: #27a8ed;
        }
        60% {
            background: #e3a922;
        }
        80% {
            background: #04a3d3;
        }
        100% {
            background: #04bed3;
        }
    }

        @keyframes backgroundImages {
        0% {
            width: 250px;
            height: 100%;
            position: relative;
            top: 40%;
            left: 55%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-purple.svg');
        }
        20% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 69%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-green.svg');
        }
        40% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 55%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-purple.svg');
        }
        70% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 71%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-org.svg');
        }
        80% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 69%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-blue.svg');
        }
        100% {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 40%;
            left: 69%;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../../assets/images/av-sales-login-org.svg');
        }
    }
  }

  /* MY STYLES OF WELCOME PAGE */

  @mixin for-extrasmall-only {
    @media only screen and (max-width: 600px) { @content; }
    }
    @mixin for-small-only {
    @media only screen and (min-width: 600px) { @content; }
    }
    @mixin for-tablets-only {
    @media only screen and (max-width: 768px) { @content; }
    }
    @mixin for-laptops-only {
    @media only screen and (min-width: 992px) { @content; }
    }
    @mixin for-large-only {
    @media only screen and (min-width: 1200px) { @content; }
    }
    @mixin for-extralarge-only {
    @media only screen and (min-width: 1400px) { @content; }
    }  

  .row {
    --bs-gutter-x: 0 !important;
}

  @include for-tablets-only {    
    .mainbuttons{
        width: 250px !important;
        max-width: 250px !important; 
        margin-left: 30px;
 }
    .buildbutton{
        margin-left: 30px !important;
    }
    .tenantbutton{
        margin-left: 30px !important;
    }
    .welcomelogo{
        left: 50px !important;
    }
}

@include for-extralarge-only{
    .quesbutton {
        position: absolute;
        top: 0px;
        right: 40px !important;
        width: 33px !important;
        height: 32px !important;
    }
}